import {combineReducers} from 'redux'
import {persistReducer} from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'

//reducers

import appReducer from './appReducer';
import user from './userReducer';
import administrators from './administratorsReducer';
import clients from './clientsReducer';
import investmentsRequests from './investmentsRequestsReducer';
import settings from './settingsReducer';
import options from './options';
import referrals from './referralsListReducer';
import payments from './paymentsReducer';
import dashboard from './dashboardReducer';
import bonuses from './bonusesReducer';
import coupons from './couponsReducer';
import promoCodes from './promoCodesReducer';
import airdrop from './airdropReducer';
import staking from './stakingReducer';
import affiliates from './affiliatesReducer';


const rootPersistConfig = {
    key: 'root',
    storage: storageSession,
    whitelist: ['options'],
};

const rootReducer = combineReducers({
    appReducer,
    dashboard,
    user,
    settings,
    administrators,
    clients,
    options,
    investmentsRequests,
    referrals,
    payments,
    bonuses,
    coupons,
    promoCodes,
    airdrop,
    staking,
    affiliates
});

export default persistReducer(rootPersistConfig, rootReducer)
